$(document).ready(function () {
   $('.box-hamburger').on('click', function () {
       $(this).toggleClass('open-menu');
       $('.box-header__langMenu').slideToggle();
   });
    $(".link-lang").click(function() {
        $(".link-lang").removeClass('active-lang');
        $(this).addClass('active-lang');
    });


    if ($(window).width() < 1035) {
        $('.active-hover').on('click', function (e) {
            e.preventDefault();
            $('.pod-menu').slideToggle();
        })
    }
    // $(window).resize(function() {
    //     if ( $(window).width() >  1035) {
    //         $('.active-hover').on('click', function (e) {
    //             e.preventDefault();
    //             $('.pod-menu').slideToggle();
    //         })
    //     }
    //
    // });
    setTimeout(function (){
            $('.active-li').hover(
                function(){
                    $('.pod-menu').css('display', 'block') ;
                    return false;
                },
                function(){ $('.pod-menu').css('display', 'none') }
            )}, 1700);




    // var nav = $('#top_nav');
    // var content = $('#top_content');
    // $(window).scroll(function () {
    //     if ($(this).scrollTop() > 1) {
    //         nav.addClass("f-nav");
    //         content.addClass('f-content')
    //     } else {
    //         nav.removeClass("f-nav");
    //         content.removeClass('f-content')
    //     }
    // });
    // $(window).scroll(function () {
    //     var HeaderTop = $('.index1').offset().top;
    //     var content = $('.header_contacts');
    //     if ($(window).scrollTop() >= HeaderTop - 79) {
    //         $(content).addClass('f-nav-two');
    //     } else {
    //         $(content).removeClass('f-nav-two');
    //     }
    // });
    // $(window).width(function(){
    //
    //     if ($(window).width() > 992){
    //         new WOW().init();
    //     }
    // });
    var swiper = new Swiper('.first-screen .swiper-container', {
        speed: 2000,
        autoplay: true,
        loop:true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });


    var swiper = new Swiper('.logotype-two .swiper-container', {
        autoplay: true,
        speed: 800,
        spaceBetween: 50,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            640: {
                slidesPerView: 4,
                spaceBetween: 70
            },
            1000:{
                slidesPerView: 7,
                spaceBetween: 40
            }
        }
    });
    var swiper = new Swiper('.sertificat .swiper-container', {
        autoplay: true,
        speed: 800,
        loop: true,
        spaceBetween: 50,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            640: {
                slidesPerView: 3,
                spaceBetween: 70
            },
            1000:{
                slidesPerView: 4,
                spaceBetween: 40
            }
        }
    });
    var one_time_start = 8;
    var one_time_finish = 10;

    var two_time_start = 11;
    var two_time_finish = 13;

    var three_time_start = 14;
    var three_time_finish = 16;

    var four_time_start = 17;
    var four_time_finish = 19;

    var five_time_start = 20;
    var five_time_finish = 23;

    var six_time_start = 0;
    var six_time_finish = 7;


    var now = new Date();
    var clientLocalHour = now.getHours();



    var one_slider = $('.one-slider').css('background-image');
    var two_slider = $('.two-slider').css('background-image');
    var three_slider = $('.three-slider').css('background-image');
    var four_slider = $('.four-slider').css('background-image');
    var five_slider = $('.five-slider').css('background-image');
    var six_slider = $('.six-slider').css('background-image');
    var seven_slider = $('.seven-slider').css('background-image');
    var eight_slider = $('.eight-slider').css('background-image');
    var nine_slider = $('.nine-slider').css('background-image');
    var ten_slider = $('.ten-slider').css('background-image');


    var none_one = $('.none-slider-one').css('background-image');
    var none_two = $('.none-slider-two').css('background-image');
    var none_three = $('.none-slider-three').css('background-image');
    var none_four = $('.none-slider-four').css('background-image');
    var none_five = $('.none-slider-five').css('background-image');

    if (clientLocalHour >= one_time_start && clientLocalHour >= one_time_finish ) {
        // $(".slider-first").prepend($(".l2 .swiper-slide:eq(0)"));
        // $(".l1").prepend($(".slider-first .swiper-slide:eq(9)"));
    }
    if (clientLocalHour >= two_time_start && clientLocalHour <= two_time_finish ) {
        // var photos_0 = $(".l2 .swiper-slide:eq(0)").css('background-image');
        $(".one-slider").css('background-image', none_one);
        $(".two-slider").css('background-image', one_slider);
        $(".three-slider").css('background-image', two_slider);
        $(".four-slider").css('background-image', three_slider);
        $(".five-slider").css('background-image', four_slider);
        $(".six-slider").css('background-image', five_slider);
        $(".seven-slider").css('background-image', six_slider);
        $(".eight-slider").css('background-image', seven_slider);
        $(".nine-slider").css('background-image', eight_slider);
        $(".ten-slider").css('background-image', nine_slider);
    }
    if (clientLocalHour >= three_time_start && clientLocalHour <= three_time_finish ) {
        // var photos_1 = $(".l2 .swiper-slide:eq(1)").css('background-image');
        // $(".one-slider").css('background-image', photos_1);

        $(".one-slider").css('background-image', none_two);
        $(".two-slider").css('background-image', none_one);
        $(".three-slider").css('background-image', one_slider);
        $(".four-slider").css('background-image', two_slider);
        $(".five-slider").css('background-image', three_slider);
        $(".six-slider").css('background-image', four_slider);
        $(".seven-slider").css('background-image', five_slider);
        $(".eight-slider").css('background-image', six_slider);
        $(".nine-slider").css('background-image', seven_slider);
        $(".ten-slider").css('background-image', eight_slider);

    }
    if (clientLocalHour >= four_time_start && clientLocalHour <= four_time_finish ) {
        $(".one-slider").css('background-image', none_three);
        $('.two-slider').css('background-image', none_two);
        $('.three-slider').css('background-image', none_one);
        $('.four-slider').css('background-image', one_slider);
        $('.five-slider').css('background-image', two_slider);
        $('.six-slider').css('background-image', three_slider);
        $('.seven-slider').css('background-image', four_slider);
        $('.eight-slider').css('background-image', five_slider);
        $('.nine-slider').css('background-image', six_slider);
        $('.ten-slider').css('background-image', seven_slider);

    }
    if (clientLocalHour >= five_time_start && clientLocalHour <= five_time_finish ) {
        $(".one-slider").css('background-image', none_four);
        $(".two-slider").css('background-image', none_three);
        $(".three-slider").css('background-image', none_two);
        $(".four-slider").css('background-image', none_one);
        $(".five-slider").css('background-image', one_slider);
        $(".six-slider").css('background-image', two_slider);
        $(".seven-slider").css('background-image', three_slider);
        $(".eight-slider").css('background-image', four_slider);
        $(".nine-slider").css('background-image', five_slider);
        $(".ten-slider").css('background-image', six_slider);

    }
    if (clientLocalHour >= six_time_start && clientLocalHour <= six_time_finish ) {
        $(".one-slider").css('background-image', none_five);
        $(".two-slider").css('background-image', none_four);
        $(".three-slider").css('background-image', none_three);
        $(".four-slider").css('background-image', none_two);
        $(".five-slider").css('background-image', none_one);
        $(".six-slider").css('background-image', one_slider);
        $(".seven-slider").css('background-image', two_slider);
        $(".eight-slider").css('background-image', three_slider);
        $(".nine-slider").css('background-image', four_slider);
        $(".ten-slider").css('background-image', five_slider);
    }

    var div = $("#box-text-scroll");
    div.scrollTop(240);



    var swiper = new Swiper('.slider-fixed .swiper-container', {
        speed: 700,
        autoplay: true,
        loop:true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });


});